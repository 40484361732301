import { useQuery } from '@tanstack/react-query'
import { ContactDriver } from 'services/contact'
import { ContactQuery, ContactQueryResponse } from 'services/contact/types'

type UseGetContacts = {
  contacts: ContactQueryResponse[] | undefined
  isFetchingContacts: boolean
}

const query = async (payload: Partial<ContactQuery>, name?: string) => {
  const result = await ContactDriver.query({
    ...payload,
    ...(name && {
      name,
    }),
  })

  return result.data
}

export const useGetContacts = (
  payload: Partial<ContactQuery>,
  enabled?: boolean,
): UseGetContacts => {
  const { data: contacts, isFetching: isFetchingContacts } = useQuery({
    queryKey: ['contacts', payload],
    queryFn: () => query(payload),
    enabled,
    refetchOnMount: false,
  })

  return { contacts, isFetchingContacts }
}
