import { Icon, ProtectedImage } from 'components'

import styles from './EquipmentPhoto.module.scss'
import { ModalImageView } from 'components/ModalImageView/ModalImageView'
import { useToggle } from 'shared/hooks'
import joinClassNames from 'utilities/joinClassNames'

export interface EquipmentPhotoProps {
  imageId: string
  active?: boolean
  alt: string
}

export const EquipmentPhoto = ({ imageId, alt }: EquipmentPhotoProps) => {
  const fullScreenImageModal = useToggle()

  return (
    <>
      <div
        className={styles.container}
        onClick={() => {
          if (imageId) fullScreenImageModal.show()
        }}
      >
        {imageId ? (
          <div className={styles.installationImage}>
            <ProtectedImage
              className={joinClassNames(styles.equipmentPhoto, styles.border)}
              imageId={imageId}
              alt={`equipamento ${alt}`}
            />
            <div className={styles.hoverImage} aria-label="overlay-modal" />
          </div>
        ) : (
          <div className={styles.emptyImage}>
            <Icon name="photo-off" color="background-default" />
          </div>
        )}
      </div>
      <ModalImageView
        isVisible={fullScreenImageModal.isVisible}
        imageIds={[imageId]}
        onClose={fullScreenImageModal.hide}
      />
    </>
  )
}
